import { Vue, Component, Prop } from 'vue-property-decorator';
import { logo } from '../../../../whitelabel.config';

const LogoImage = require(`../../../assets/logos/${logo}?inline`).default;

@Component({
  components: {
    LogoImage,
  },
})

export default class Logo extends Vue {
  classes: string[] = ['logo'];

  @Prop({ default: 'small' })
  size!: string;

  /**
   * Computed property to get
   * the classes of the logo.
   *
   * @return string
   */
  get logoClasses(): string {
    this.classes.push(`logo--${this.size}`);

    return this.classes.join(' ');
  }
}
