import { Investor, BankAccountChange, User, DataChangeRequest } from './user';
import { Payment, Investment } from './investment';
import { Asset } from './asset';
import { IdentificationRequest } from './identificationRequest';
import { IdinIssuers, Idin } from './idin';

/**
 * Generic Bloqify settings.
 */
export interface Settings {
  version: string;
  updatedDateTime: string;
}

/**
 * Admin settings. Shuts down the application.
 */
export interface Admin {
  maintenance: boolean;
  bloqadminMaintenace: boolean;
}

/**
 * Handles the mandatory Identification Rrequest needed to invest.
 */
export interface IdentificationSettings {
  requireApproval: {
    business: boolean,
    private: boolean,
  },
}

/**
 * Vuex store's State (tree).
 */
export interface State {
  auth: { [key: string]: any } | null;
  user: User | Investor | null;
  assets: Asset[];
  idin: Idin | null;
  investments: Investment[],
  payment: StateSlice;
  payments: Payment[];
  support: { [key: string]: any } | null;
  manualOrder: { [key: string]: any } | null;
  operations: StateSlice;
  idinIssuers: IdinIssuers | null;
  identificationRequest: IdentificationRequest | null;
  dataChangeRequests: DataChangeRequest[] | null;
  bankAccountChanges: BankAccountChange[];
  downloads: Downloads | null;
  settings: Settings | null;
  admin: Admin | null;
  identificationSettings: IdentificationSettings | null;
  authModal: AuthModal;
  misc: {
    initialTooltip: boolean,
    showGDPR: boolean,
    showUpgradeInfobox: boolean,
  }
  [key: string]: { [key: string]: any } | null | any[];
}

/**
 * Main basic unit in the state.
 */
export interface StateSlice<T = any> {
  status: string;
  payload?: T | any;
  error?: string;
  name?: string;
}

/**
 * Implementing StateSlice.
 */
export class InitialStateSlice implements StateSlice {
  status = '';
  payload = null;
  error = '';
  name = '';
}

/**
 * Function that generates the store avoiding (or not) the modules.
 * @param includeModules handles the inclusion of the modules in the freshly generated state.
 */
export const generateInitialRootState = (includeModules?: boolean): { [key: string]: any } => ({
  auth: null,
  user: null,
  support: null,
  manualOrder: null,
  operations: new InitialStateSlice(),
  idinIssuers: null,
  identificationRequest: null,
  dataChangeRequests: null,
  bankAccountChanges: [],
  settings: null,
  admin: null,
  identificationSettings: null,
  misc: {
    initialTooltip: false,
    showGDPR: false,
    showUpgradeInfobox: false,
  },

  // Including all the modules
  ...includeModules && {
    assets: [],
    investments: [],
    payments: [],
    idin: null,
    payment: new InitialStateSlice(),
    downloads: {},
  },
});

// -- DOWNLOADS -- //
/**
 * State interface for the withDownloads wrapper.
 */
export interface Downloads {
  assets?: {
    [key: string]: AssetDownloads;
  };
  investor?: UserDownloads;
}

/**
 * State structure for an asset's files.
 */
export interface AssetDownloads {
  images?: StateSlice<[string, string][]>;
  floorPlanImages?: StateSlice<[string, string][]>;
  prospectus?: StateSlice<[string, string][]>;
  brochure?: StateSlice<[string, string][]>;
}

/**
 * State structure for the user's files.
 */
export interface UserDownloads {
  avatar?: StateSlice<[string, string][]>;
}

// -- AUTH -- //
export interface AuthModal {
  isOpen: boolean,
  type: AuthComponents | null,
  routeTo?: string | null,
}

// enum with all pages of the Auth, thus string = path
export enum AuthComponents {
  Login = 'login',
  AuthVerification = 'auth-verification',
  Reset = 'reset',
  Register = 'register',
}
