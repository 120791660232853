/* eslint-disable max-len */
/* eslint no-console: "off" */
import Vue from 'vue';
import VueGtm from 'vue-gtm';
import router from '@/router';
import VueIntercom from 'vue-intercom';
import { intercom } from '../whitelabel.config';

// const { intercomConfig } = require('../whitelabel.config');

const env = process.env;
const NODE_ENV = env.NODE_ENV;
const isProductionEnvironment = NODE_ENV === 'production';
export const localStorageLocation = 'gdprPermissions';

/*
* In order to have our code align with the google documentation we use a dummy gtag function here
* Also the typings of the GTM Vue plugin seem off, since we can pass on arguments to the dataLayer,
* but this has all to be tested in production
* */
function gtag(...args: any): any // overloading to accept any number of arguments
function gtag(): any { // @ts-ignore
  // eslint-disable-next-line prefer-rest-params
  Vue.gtm.dataLayer(arguments);
}

export interface GdprPermission {
  serviceName: string, // for forced updates of consent the serviceName can be changed
  title: { // the title shown to the user
    nl: string,
    en: string,
  },
  bodyText1: { // the the text always shown to users
    nl: string,
    en: string,
  },
  bodyText2?: { // the text shown to the user after clicking 'more'
    nl: string,
    en: string,
  },
  accepted: boolean | undefined, // whether the user has actively given consent or not, undefined indicates that the user has not done either
  required: boolean // indicating whether this tracker is required
  consentRequired: boolean; // if consent is not required the required can't be true
  enable: (app: Vue) => void, // function which enables the specific tracker
  disable: (app: Vue) => void, // function which disables the specific tracker
}

export type GdprPermissions = GdprPermission[];

/* This type corresponds to the object saved in the local storage */
export type GdprPermissionLocalStorage = Pick<GdprPermission, 'serviceName' | 'accepted'>;

/* array which acts as single source of truth of all trackers, and their categorisation */
export const gdprPermissions: GdprPermission[] = [
  {
    serviceName: 'functional',
    title: {
      nl: 'Functionele cookies',
      en: 'Functional cookies',
    },
    bodyText1: {
      nl: '',
      en: '',
    },
    bodyText2: {
      nl: 'Deze cookies plaatsen wij altijd. Deze cookies zijn noodzakelijk en u kunt deze cookies niet weigeren. De cookies zorgen er bijvoorbeeld voor dat de website technisch goed werkt en beveiligd is.',
      en: 'These cookies are always used by us. They are required and you cannot opt-out of them. For example ensure these cookies that this site works technically well and is secure.',
    },
    accepted: undefined,
    required: false,
    consentRequired: false,
    enable: (app): void => {
      if (!isProductionEnvironment) {
        console.log('enable functional cookies');
      }
      if (intercom && env.VUE_APP_INTERCOM) {
        app.$intercom.boot();
      }
      // in pebbles we always use gtag
      gtag('consent', 'update', {
        ad_storage: 'granted',
        wait_for_update: 500,
      });
    },
    disable: (app): void => {
      if (!isProductionEnvironment) {
        console.log('disable functional cookies');
      }
      app.$intercom.shutdown();
    },
  },
];

export const executePermissions = (permissions: GdprPermissions, app: Vue): void => {
  /* activate gtm with the default all denied */
  gtag('consent', 'default', {
    ad_storage: 'denied',
    analytics_storage: 'denied',
    wait_for_update: 500,
  });
  permissions.forEach((permission): void => {
    if (permission.accepted || !permission.consentRequired) {
      permission.enable(app);
    } else {
      permission.disable(app);
    }
  });
};

/*
* Executed before the new instance is created,
* thus this is the place where plugins for trackers should be initialised
* */
export const trackerSetupBeforeInit = (): void => {
  /* activate gtm
   *  So far we only have google analytics in the GTM in general
   *  If we use the facebook pixel we need to set a variable in the datalayer indicating whether consent is given or not (this should happen in the correct enable() function.
   *  Then there needs to have the right config in the gtm website to have this setting be respected see: https://www.analyticsmania.com/post/facebook-pixel-with-google-tag-manager/#consent
   * Use the consent mode works with gtm https://www.simoahava.com/analytics/consent-mode-google-tags/
   * */
  const analyticsConfig = {
    id: env.VUE_APP_GOOGLE_GTM,
    enabled: isProductionEnvironment, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: !isProductionEnvironment, // Whether or not display console logs debugs (optional)
    loadScript: true,
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    // ignoredViews: ['homepage'] // If router, you can exclude some routes name (case insensitive) (optional)
  };
  Vue.use(VueGtm, analyticsConfig);

  if (intercom && env.VUE_APP_INTERCOM) {
    Vue.use(VueIntercom, {
      appId: env.VUE_APP_INTERCOM,
    });
  }
};

// in order to not have to ignore all places where we access $intercom we extend Vue here with it
declare module 'vue/types/vue' {
  interface Vue {
    $intercom: any,
  }
}
