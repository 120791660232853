import VueI18n from 'vue-i18n';
import to from 'await-to-js';
import Vue from 'vue';

Vue.use(VueI18n);

export const defaultLanguage: string = 'nl';
export const localStorageKey: string = 'vue-i18n-language';

export type Language = 'nl';
export const languages: Language[] = ['nl'];

export const isValidLang = (lang): lang is Language => languages.includes(lang);

export const i18n = new VueI18n({
  locale: defaultLanguage, // set locale
  fallbackLocale: 'nl',
  messages: {},
});

// Setting default language
import(/* webpackChunkName: "lang-[request]" */ '@/lang/nl.ts')
  .then((langFile): void => {
    i18n.setLocaleMessage(defaultLanguage, langFile.default[defaultLanguage]);
  })
  .catch((e): void => {
    throw new Error(e);
  });

// Lazy loading of different language
export async function loadLanguageAsync(lang: Language): Promise<boolean> {
  if (i18n.locale !== lang) {
    if (!i18n.messages[lang]) {
      const [msgsError, msgsSuccess] = await to(import(/* webpackChunkName: "lang-[request]" */ `@/lang/${lang}.ts`));
      if (msgsError) {
        console.log('Language error', msgsError); // eslint-disable-line
        return false;
      }
      i18n.setLocaleMessage(lang, msgsSuccess.default[lang]);
    }
    i18n.locale = lang;
  }
  localStorage.setItem(localStorageKey, lang);
  return true;
}
