import { auth } from '@/firebase';
import moduleRoutes from '@/modules/routes';
import { defaultLanguage, isValidLang, loadLanguageAsync, localStorageKey } from '@/i18n';
import { IdentCheckoutRouteNames, IdentifcationPath, steps } from '@/helpers/checkout';
import { AuthComponents } from '@/store/models';
// eslint-disable-next-line import/extensions
import { CheckoutStepNames, Step } from '@/store/models/checkout.ts';
import Vue from 'vue';
import Router, { Route, RouteConfig } from 'vue-router';
import { glossary } from '../../whitelabel.config';

Vue.use(Router);

/**
 * Prefixing routes' paths to handle language
 */
const withPrefix = (
  prefix: string,
  routes: RouteConfig[],
): RouteConfig[] => routes.map((route): RouteConfig => {
  // Avoiding mutations
  const clonedRoute = { ...route };
  // Every route except for '/'
  if (clonedRoute.path !== '/') {
    clonedRoute.path = prefix + clonedRoute.path;
  }
  return clonedRoute;
});

const mainRoutes: RouteConfig[] = [
  {
    path: '/',
    redirect: '/projects',
  },
  {
    path: '/cookie-policy',
    component: (): Promise<object> => import(/* webpackChunkName: "cookie" */ '@/components/cookie-policy/CookiePolicy.vue'),
  },
  {
    path: '/cookie-beleid',
    component: (): Promise<object> => import(/* webpackChunkName: "cookie" */ '@/components/cookie-policy/CookiePolicy.vue'),
  },
  {
    path: '/privacy-policy',
    component: (): Promise<object> => import(/* webpackChunkName: "privacy" */ '@/components/privacy-policy/PrivacyPolicy.vue'),
  },
  {
    path: '/disclaimer',
    component: (): Promise<object> => import(/* webpackChunkName: "disclaimer" */ '@/components/disclaimer/Disclaimer.vue'),
  },
  {
    path: '/prospectus',
    component: (): Promise<object> => import(/* webpackChunkName: "prospectus" */ '@/components/prospectus/Prospectus.vue'),
  },
  {
    path: '/login',
    component: (): Promise<object> => import(/* webpackChunkName: "auth" */ '@/components/common/auth/auth-page/AuthPage.vue'),
    props: { initWithType: AuthComponents.Login },
    meta: {
      requiresLogout: true,
    },
  },
  {
    path: '/checkout/investment/:id',
    component: (): Promise<object> => import(/* webpackChunkName: "checkout" */ '@/components/checkout/Checkout.vue'),
    props: { initWithType: CheckoutStepNames.Investment },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/checkout/investering/:id',
    component: (): Promise<object> => import(/* webpackChunkName: "checkout" */ '@/components/checkout/Checkout.vue'),
    props: { initWithType: CheckoutStepNames.Investment },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/checkout/status/:id/:paymentId',
    component: (): Promise<object> => import(/* webpackChunkName: "checkout" */ '@/components/checkout/Checkout.vue'),
    name: 'checkoutStatus',
    props: { initWithType: CheckoutStepNames.Status },
    meta: {
      requiresAuth: true,
    },
  },
  ...steps.reduce((routes: RouteConfig[], step: Step): RouteConfig[] => {
    let selectedRoutes = [...routes];
    if (step.name === CheckoutStepNames.Identification) {
      selectedRoutes = selectedRoutes.concat([
        {
          path: `/checkout/${IdentifcationPath}/`,
          component: (): Promise<object> => import(/* webpackChunkName: "checkout" */ '@/components/checkout/Checkout.vue'),
          props: { initWithType: CheckoutStepNames.Identification, header: false },
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: ':investmentId/',
              name: IdentCheckoutRouteNames.MAIN,
              component: (): Promise<object> =>
                import(/* webpackChunkName: "identification" */ '@/modules/identification/components/Identification.vue'),
              props: { header: false },
              children: [
                {
                  path: 'idin/',
                  name: IdentCheckoutRouteNames.IDIN,
                  component: (): Promise<object> =>
                    import(/* webpackChunkName: "identification" */ '@/modules/identification/components/idin/IdentificationIdin.vue'),
                  props: { header: false },
                },
                {
                  path: 'world/',
                  name: IdentCheckoutRouteNames.WORLD,
                  component: (): Promise<object> =>
                    import(/* webpackChunkName: "identification" */ '@/modules/identification/components/world/IdentificationWorld.vue'),
                  props: { header: false },
                },
                {
                  path: 'business/',
                  name: IdentCheckoutRouteNames.BUSINESS,
                  component: (): Promise<object> =>
                    import(/* webpackChunkName: "identification" */ '@/modules/identification/components/business/IdentificationBusiness.vue'),
                  props: { header: false },
                },
              ],
            },
          ],
        },
      ]);
    }
    if (step.name === CheckoutStepNames.Terms) {
      selectedRoutes = selectedRoutes.concat([
        {
          path: '/checkout/legal/:id',
          component: (): Promise<object> => import(/* webpackChunkName: "checkout" */ '@/components/checkout/Checkout.vue'),
          props: { initWithType: CheckoutStepNames.Terms },
          meta: {
            requiresAuth: true,
          },
          name: CheckoutStepNames.Terms,
        },
      ]);
    }
    return selectedRoutes;
  }, []),
  {
    path: '/register',
    component: (): Promise<object> => import(/* webpackChunkName: "auth" */ '@/components/common/auth/auth-page/AuthPage.vue'),
    props: { initWithType: AuthComponents.Register },
    meta: {
      requiresLogout: true,
    },
  },
  {
    path: '/registreren',
    component: (): Promise<object> => import(/* webpackChunkName: "auth" */ '@/components/common/auth/auth-page/AuthPage.vue'),
    props: { initWithType: AuthComponents.Register },
    meta: {
      requiresLogout: true,
    },
  },
  {
    path: '/reset',
    component: (): Promise<object> => import(/* webpackChunkName: "auth" */ '@/components/common/auth/auth-page/AuthPage.vue'),
    props: { initWithType: AuthComponents.Reset },
    meta: {
      requiresLogout: true,
    },
  },
  {
    path: '/auth-verification',
    component: (): Promise<object> => import(/* webpackChunkName: "auth" */ '@/components/common/auth/auth-page/AuthPage.vue'),
    props: { initWithType: AuthComponents.AuthVerification },
    meta: {
      requiresLogout: true,
    },
  },
  {
    path: '/auth-verificatie',
    component: (): Promise<object> => import(/* webpackChunkName: "auth" */ '@/components/common/auth/auth-page/AuthPage.vue'),
    props: { initWithType: AuthComponents.AuthVerification },
    meta: {
      requiresLogout: true,
    },
  },
  {
    path: '/error/:errorType?',
    component: (): Promise<object> => import(/* webpackChunkName: "error" */ '@/components/common/StaticErrors/StaticErrors.vue'),
  },
  {
    path: '/activate/:activateType?/:id?',
    component: (): Promise<object> => import(/* webpackChunkName: "error" */ '@/components/common/activate/Activate.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/activeren/:activateType?/:id?',
    component: (): Promise<object> => import(/* webpackChunkName: "error" */ '@/components/common/activate/Activate.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/account',
    component: (): Promise<object> => import(/* webpackChunkName: "account" */ '@/components/account/Account.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        component: (): Promise<object> => import(/* webpackChunkName: "account" */ '@/components/account/dashboard/AccountDashboard.vue'),
      },
      {
        path: 'settings',
        component: (): Promise<object> => import(/* webpackChunkName: "account" */ '@/components/account/settings/AccountSettings.vue'),
        redirect: '/account/settings/details',
        children: [
          {
            path: 'details',
            component: (): Promise<object> =>
              import(/* webpackChunkName: "account" */ '@/components/account/settings/details/AccountSettingsDetails.vue'),
          },
          {
            path: 'identification',
            component: (): Promise<object> =>
              import(/* webpackChunkName: "account" */ '@/components/account/settings/identification/AccountSettingsIdentification.vue'),
          },
        ],
      },
      {
        path: 'instellingen',
        name: 'settings',
        component: (): Promise<object> => import(/* webpackChunkName: "account" */ '@/components/account/settings/AccountSettings.vue'),
        redirect: '/account/instellingen/detail',
        children: [
          {
            path: 'detail',
            name: 'settings-details',
            component: (): Promise<object> =>
              import(/* webpackChunkName: "account" */ '@/components/account/settings/details/AccountSettingsDetails.vue'),
          },
          {
            path: 'identificatie',
            name: 'settings-identification',
            component: (): Promise<object> =>
              import(/* webpackChunkName: "account" */ '@/components/account/settings/identification/AccountSettingsIdentification.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/properties',
    component: (): Promise<object> => import(/* webpackChunkName: "properties" */ '@/components/properties/Properties.vue'),
  },
  {
    path: '/vastgoed',
    name: 'properties',
    component: (): Promise<object> => import(/* webpackChunkName: "properties" */ '@/components/properties/Properties.vue'),
  },
  {
    path: '/property/:id',
    component: (): Promise<object> => import(/* webpackChunkName: "property" */ '@/components/properties/property/Property.vue'),
  },
  {
    path: '/vastgoedspecifiek/:id',
    name: 'property',
    component: (): Promise<object> => import(/* webpackChunkName: "property" */ '@/components/properties/property/Property.vue'),
  },
  {
    path: '/how-it-works',
    component: (): Promise<object> => import(/* webpackChunkName: "about-us" */ '@/components/how-it-works/HowItWorks.vue'),
  },
  {
    path: '/hoe-werkt-pebbles',
    name: 'how-it-works',
    component: (): Promise<object> => import(/* webpackChunkName: "about-us" */ '@/components/how-it-works/HowItWorks.vue'),
  },
  {
    path: '/about-us',
    component: (): Promise<object> => import(/* webpackChunkName: "about-us" */ '@/components/about-us/AboutUs.vue'),
  },
  {
    path: '/over-ons',
    name: 'about-us',
    component: (): Promise<object> => import(/* webpackChunkName: "about-us" */ '@/components/about-us/AboutUs.vue'),
  },
  {
    path: '/faqs',
    component: (): Promise<object> => import(/* webpackChunkName: "faqs" */ '@/components/knowledge/Knowledge.vue'),
    redirect: '/faqs/faq',
    children: [
      {
        path: 'faq',
        name: 'faqs-faq',
        component: (): Promise<object> =>
          import(/* webpackChunkName: "faqs" */ '@/components/knowledge/faq/KnowledgeFaq.vue'),
      },
      {
        path: 'glossary',
        name: 'faqs-glossary',
        ...glossary
          ? { component: (): Promise<object> => import(/* webpackChunkName: "faqs" */ '@/components/knowledge/glossary/KnowledgeGlossary.vue') }
          : { redirect: '/faqs/faq' },
      },
    ],
  },
  {
    path: '/news',
    component: (): Promise<object> => import(/* webpackChunkName: "faqs" */ '@/components/news/News.vue'),
    redirect: '/news/articles',
    children: [
      {
        path: 'articles',
        component: (): Promise<object> =>
          import(/* webpackChunkName: "faqs" */ '@/components/news/articles/NewsArticles.vue'),
      },
    ],
  },
  {
    path: '/nieuws',
    component: (): Promise<object> => import(/* webpackChunkName: "news" */ '@/components/news/News.vue'),
    redirect: '/nieuws/artikelen',
    children: [
      {
        path: 'artikelen',
        name: 'news-articles',
        component: (): Promise<object> =>
          import(/* webpackChunkName: "news-articles" */ '@/components/news/articles/NewsArticles.vue'),
      },
      {
        path: 'achtergrond',
        name: 'news-background',
        component: (): Promise<object> =>
          import(/* webpackChunkName: "news-background" */ '@/components/news/background/NewsBackground.vue'),
      },
      {
        path: 'pers',
        name: 'news-press',
        component: (): Promise<object> =>
          import(/* webpackChunkName: "news-press" */ '@/components/news/press/NewsPress.vue'),
      },
    ],
  },
];

const errorRedirectRoute: RouteConfig = {
  path: '/*',
  beforeEnter: (to, from, next): void => {
    const navigationLang = to.params.lang;
    if (navigationLang) {
      return next(`/${navigationLang}/error/404`);
    }
    return next('/error/404');
  },
};

// Expanding all the routes from the modules into one array
const moduleRoutesAsArray = Object.entries(moduleRoutes).reduce(
  (
    previousArray,
    nextRouteKeyPair,
  ): RouteConfig[] => previousArray.concat(nextRouteKeyPair[1]), [] as RouteConfig[],
);

// Filtering all the routes that are already as module routes (by path)
const filteredMainRoutes = mainRoutes.filter((route): boolean => !moduleRoutesAsArray.some((mRoute): boolean => mRoute.path === route.path));

// First the main routes with replicated ones with moduleRoutes removed
// Then the routes from the modules
// Then the generic error redirect
const finalRoutes = [...filteredMainRoutes, ...moduleRoutesAsArray, errorRedirectRoute];

const router = new Router({
  // Mixing all routes into one final array
  routes: withPrefix('/:lang?', finalRoutes),
  scrollBehavior(to, from, savedPosition): any {
    return { x: 0, y: 0 };
  },
  // History is cleaner and a must for prerendering
  mode: 'history',
});

// Checking all the meta requirements for the going (next) page
const checkMeta = (to: Route, requires: string[]): { [key: string]: boolean } => {
  const metaResult = {};

  requires.forEach((req): void => {
    metaResult[req] = to.matched.some((record): boolean => record.meta[req]);
  });

  return metaResult;
};

router.beforeEach((to, from, next): void => {
  const { currentUser } = auth;
  const meta = checkMeta(to, ['requiresAuth', 'requiresIdin', 'requiresLogout']);

  const pagesToBeChecked = ['/property', '/vastgoed'];
  const redirectToWhenLoggingIn = (path: string): boolean => pagesToBeChecked.some((page): boolean => path.startsWith(page));

  // Setting language according to where we go:
  // If user wants to change to specific lang (to)
  // If user remains in same lang (from)
  // User is in no lang mode (defaultLanguage)
  const { lang } = to.params;
  const { lang: previousLang } = from.params;
  const localStorageLang = localStorage.getItem(localStorageKey);
  const toLang = lang || previousLang || localStorageLang || defaultLanguage;
  const fullToLang = toLang ? `/${toLang}` : '';
  if (isValidLang(toLang)) {
    loadLanguageAsync(toLang);
  }

  switch (true) {
    // Pages that the user needs to be logged out (or email not verified) to see them
    case meta.requiresLogout && currentUser && currentUser.emailVerified:
      next({ path: '/vastgoed' });
      break;
    // Pages the user needs to be logged in && email activated to see them
    case meta.requiresAuth && (!currentUser || !currentUser.emailVerified):
      if (from.fullPath === '/') { // no previous page we can directly redirect to the login
        next({ path: '/login' });
      } else { // otherwise we open the modal
        router.app.$store.dispatch('openModal', { type: AuthComponents.Login, routeTo: to.fullPath });
        next(false);
      }
      break;
    // Pages that need to be redirected to after logging in
    case to.path.startsWith('/login') && redirectToWhenLoggingIn(from.path) && Object.keys(to.query).length === 0:
      if (from.fullPath === '/') {
        next({ path: '/login' });
      } else {
        router.app.$store.dispatch('openModal', { type: AuthComponents.Login, routeTo: to.fullPath });
        next(false);
      }
      break;
    default: {
      const redirectTo = lang ? to.fullPath : `${fullToLang}${to.fullPath}`;
      if (to.fullPath !== redirectTo) {
        next({ path: redirectTo });
        return;
      }
      next();
    }
  }
});

export default router;
