import { GetterTree } from 'vuex';
import { UserTier } from '@/store/models/user';
import { State } from './models';
import { IdentificationRequestStatus } from './models/identificationRequest';

export default <GetterTree<State, State>> {
  isUserLoggedIn: (state): boolean => state.auth?.status === 'success',
  /**
   * Typeguard getter to check if the user is an investor.
   */
  isInvestor: ({ user }): boolean => user?.tier === UserTier.Investor,
};
