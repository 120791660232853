import { Module } from 'vuex';
import { State } from '@/store/models';

export default <Module<any, State>>{
  getters: {
    getTotalDividendsByAsset: (state, getters, rootState): Function =>
      (assetId: string): number => {
        const foundAsset = rootState.assets.find((asset): boolean => asset.id === assetId);
        const foundInvestment = rootState.investments.find((inv): boolean => inv.asset.id === assetId);

        if (foundAsset && foundInvestment) {
            const { totalValueShares, totalDividendAmount = 0 } = foundAsset;
            const { boughtSharesTotal = 0 } = foundInvestment;
            return boughtSharesTotal * (totalDividendAmount / totalValueShares);
        }

        return 0;
      },
      getTotalDividends: (state, getters, rootState): number =>
        rootState.investments.reduce((prevInv, currentInv): number => prevInv + getters.getTotalDividendsByAsset(currentInv.asset.id), 0),
  },
};
