/**
 * Exporting all the variables depending if we are in develop or in a brand specific branch
 */

module.exports = {
  logo: 'pebbles.svg',
  logoalt: 'pebbles-white.svg',
  logoShare: 'pebbles.svg',
  scssVariables: './src/scss/variables/brands/_pebbles.scss',
  name: 'Pebbles',
  phone: '020-57 20 120',
  email: 'info@pebbles.eu',
  website: 'https://pebbles.eu',
  glossary: true,
  modules: {
    landing: true,
    identification: true,
  },
  langFileName: 'pebbles.ts',
  intercom: false,
  requireContractAgreement: false,
};
